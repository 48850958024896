<template>
  <div
    style="height: inherit"
  >
    <b-card
      no-body
      class="p-2"
    >
      <b-row class="align-items-center justify-content-between">
        <b-col
          sm="12"
          md="6"
        >
          <h5 class="m-0">
            Match info
          </h5>
        </b-col>
      </b-row>
    </b-card>

    <b-row
      v-if="isLoading"
      class="ml-1 text-center"
    >
      <b-spinner label="Loading..." />
    </b-row>

    <b-card
      v-else
      no-body
      class="p-2"
    >
      <b-row>
        <b-col
          cols="5"
          class="d-flex flex-column align-items-center"
        >
          <b-avatar
            :src="match?.opponent1?.participant?.avatar_image"
            size="80"
            variant="secondary"
          />

          <h3>
            {{
                match?.opponent1?.participant
                    ? match?.opponent1?.participant?.name
                    : match?.opponent1?.name
            }}
          </h3>

          <b-row class="d-flex justify-space-around">
            <b-col sm="12">
              <b-badge
                v-for="(participant, index) in match?.opponent1?.lineup"
                :key="index"
                variant="primary"
                class="m-1"
              >
                {{ participant?.participant?.name || participant?.name }}
              </b-badge>
            </b-col>
          </b-row>
        </b-col>

        <b-col class="d-flex align-items-center justify-content-center">
          <div class="d-flex flex-column align-items-center">
            <h3>VS</h3>
          </div>
        </b-col>

        <b-col
          cols="5"
          class="d-flex flex-column align-items-center"
        >
          <b-avatar
            :src="match?.opponent2?.participant?.avatar_image"
            size="80"
            variant="secondary"
          />

          <h3>
            {{
              match?.opponent2?.participant
                  ? match?.opponent2?.participant?.name
                  : match?.opponent2?.name
            }}
          </h3>

          <b-row class="d-flex justify-space-around">
            <b-col sm="12">
              <b-badge
                v-for="(participant, index) in match?.opponent2?.lineup"
                :key="index"
                variant="primary"
                class="m-1"
              >
                {{ participant?.participant?.name || participant?.name }}
              </b-badge>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- Match status -->
      <b-row>
        <b-col class="d-flex justify-content-center mt-2">
          <h4>{{ matchStatusInfo }}</h4>
        </b-col>
      </b-row>

      <!-- Match result -->
      <b-row v-if="match?.opponent1_result && match?.opponent2_result">
        <!-- Team 1 -->
        <b-col
          class="d-flex flex-column align-items-center mt-1"
          cols="5"
        >
          <div v-if="match?.opponent1_score">
            <h2>{{ match?.opponent1_score }}</h2>
          </div>

          <div>
            <b-badge
              :variant="matchResultBadgeColor(match?.opponent1_result)"
              class="p-sm-1 font-medium-2"
            >
              {{ matchResultText(match?.opponent1_result) }}
            </b-badge>
          </div>
        </b-col>

        <b-col cols="2" />

        <!-- Team 2 -->
        <b-col
          class="d-flex flex-column align-items-center mt-1"
          cols="5"
        >
          <div v-if="match?.opponent2_score">
            <h2>{{ match?.opponent2_score }}</h2>
          </div>
          <div>
            <b-badge
              :variant="matchResultBadgeColor(match?.opponent2_result)"
              class="p-sm-1 font-medium-2"
            >
              {{ matchResultText(match?.opponent2_result) }}
            </b-badge>
          </div>
        </b-col>
      </b-row>

    </b-card>
  </div>
</template>
<script>
import {
  BAvatar,
  BBadge,
  BCard, BCol, BRow, BSpinner,
} from 'bootstrap-vue'
import MatchStatusType from '@/constants/MatchStatusType'

export default {
  components: {
    BSpinner,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
  },
  data() {
    return {
      MatchStatusType,
      match: null,
      isLoading: false,
    }
  },
  computed: {
    tournament() {
      return this.$store.getters['tournament/getTournament']
    },
    title() {
      const opponent1Name = this.match?.opponent1?.participant?.participant
        ? this.match?.opponent1?.participant?.participant?.name
        : this.match?.opponent1?.participant?.name

      const opponent2Name = this.match?.opponent2?.participant?.participant
        ? this.match?.opponent2?.participant?.participant?.name
        : this.match?.opponent2?.participant?.name

      return `${opponent1Name || '?'} vs ${opponent2Name || '?'
      } `
    },
    matchStatusInfo() {
      switch (this.match?.status_id) {
        case MatchStatusType.READY:
          return 'Match not played yet.'
        case MatchStatusType.RUNNING:
          return 'In progress.'
        case MatchStatusType.COMPLETE:
          return 'Match finished.'
        case MatchStatusType.LOCKED:
        case MatchStatusType.WAITING:
          return 'Match not ready yet.'
        default:
          return 'Match does not exist.'
      }
    },
  },
  async mounted() {
    this.isLoading = true

    const { data } = await this.$api.tournament.matchById(this.$route.params.matchId)

    this.isLoading = false

    this.match = data
  },
  methods: {
    // eslint-disable-next-line consistent-return
    matchResultText(result) {
      // eslint-disable-next-line default-case
      switch (result?.toLowerCase()) {
        case 'w':
          return 'WON'
        case 'l':
          return 'LOST'
        case 'd':
          return 'DRAW'
      }
    },
    // eslint-disable-next-line consistent-return
    matchResultBadgeColor(result) {
      // eslint-disable-next-line default-case
      switch (result?.toLowerCase()) {
        case 'w':
          return 'success'
        case 'l':
          return 'danger'
        case 'd':
          return 'secondary'
      }
    },
  },
}
</script>

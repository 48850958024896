var render = function () {
  var _vm$match, _vm$match$opponent, _vm$match$opponent$pa, _vm$match2, _vm$match2$opponent, _vm$match3, _vm$match3$opponent, _vm$match3$opponent$p, _vm$match4, _vm$match4$opponent, _vm$match5, _vm$match5$opponent, _vm$match6, _vm$match6$opponent, _vm$match6$opponent$p, _vm$match7, _vm$match7$opponent, _vm$match8, _vm$match8$opponent, _vm$match8$opponent$p, _vm$match9, _vm$match9$opponent, _vm$match10, _vm$match10$opponent, _vm$match11, _vm$match12, _vm$match13, _vm$match14, _vm$match15, _vm$match16, _vm$match17, _vm$match18, _vm$match19, _vm$match20;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "height": "inherit"
    }
  }, [_c('b-card', {
    staticClass: "p-2",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', {
    staticClass: "align-items-center justify-content-between"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('h5', {
    staticClass: "m-0"
  }, [_vm._v(" Match info ")])])], 1)], 1), _vm.isLoading ? _c('b-row', {
    staticClass: "ml-1 text-center"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _c('b-card', {
    staticClass: "p-2",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex flex-column align-items-center",
    attrs: {
      "cols": "5"
    }
  }, [_c('b-avatar', {
    attrs: {
      "src": (_vm$match = _vm.match) === null || _vm$match === void 0 ? void 0 : (_vm$match$opponent = _vm$match.opponent1) === null || _vm$match$opponent === void 0 ? void 0 : (_vm$match$opponent$pa = _vm$match$opponent.participant) === null || _vm$match$opponent$pa === void 0 ? void 0 : _vm$match$opponent$pa.avatar_image,
      "size": "80",
      "variant": "secondary"
    }
  }), _c('h3', [_vm._v(" " + _vm._s((_vm$match2 = _vm.match) !== null && _vm$match2 !== void 0 && (_vm$match2$opponent = _vm$match2.opponent1) !== null && _vm$match2$opponent !== void 0 && _vm$match2$opponent.participant ? (_vm$match3 = _vm.match) === null || _vm$match3 === void 0 ? void 0 : (_vm$match3$opponent = _vm$match3.opponent1) === null || _vm$match3$opponent === void 0 ? void 0 : (_vm$match3$opponent$p = _vm$match3$opponent.participant) === null || _vm$match3$opponent$p === void 0 ? void 0 : _vm$match3$opponent$p.name : (_vm$match4 = _vm.match) === null || _vm$match4 === void 0 ? void 0 : (_vm$match4$opponent = _vm$match4.opponent1) === null || _vm$match4$opponent === void 0 ? void 0 : _vm$match4$opponent.name) + " ")]), _c('b-row', {
    staticClass: "d-flex justify-space-around"
  }, [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, _vm._l((_vm$match5 = _vm.match) === null || _vm$match5 === void 0 ? void 0 : (_vm$match5$opponent = _vm$match5.opponent1) === null || _vm$match5$opponent === void 0 ? void 0 : _vm$match5$opponent.lineup, function (participant, index) {
    var _participant$particip;
    return _c('b-badge', {
      key: index,
      staticClass: "m-1",
      attrs: {
        "variant": "primary"
      }
    }, [_vm._v(" " + _vm._s((participant === null || participant === void 0 ? void 0 : (_participant$particip = participant.participant) === null || _participant$particip === void 0 ? void 0 : _participant$particip.name) || (participant === null || participant === void 0 ? void 0 : participant.name)) + " ")]);
  }), 1)], 1)], 1), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('h3', [_vm._v("VS")])])]), _c('b-col', {
    staticClass: "d-flex flex-column align-items-center",
    attrs: {
      "cols": "5"
    }
  }, [_c('b-avatar', {
    attrs: {
      "src": (_vm$match6 = _vm.match) === null || _vm$match6 === void 0 ? void 0 : (_vm$match6$opponent = _vm$match6.opponent2) === null || _vm$match6$opponent === void 0 ? void 0 : (_vm$match6$opponent$p = _vm$match6$opponent.participant) === null || _vm$match6$opponent$p === void 0 ? void 0 : _vm$match6$opponent$p.avatar_image,
      "size": "80",
      "variant": "secondary"
    }
  }), _c('h3', [_vm._v(" " + _vm._s((_vm$match7 = _vm.match) !== null && _vm$match7 !== void 0 && (_vm$match7$opponent = _vm$match7.opponent2) !== null && _vm$match7$opponent !== void 0 && _vm$match7$opponent.participant ? (_vm$match8 = _vm.match) === null || _vm$match8 === void 0 ? void 0 : (_vm$match8$opponent = _vm$match8.opponent2) === null || _vm$match8$opponent === void 0 ? void 0 : (_vm$match8$opponent$p = _vm$match8$opponent.participant) === null || _vm$match8$opponent$p === void 0 ? void 0 : _vm$match8$opponent$p.name : (_vm$match9 = _vm.match) === null || _vm$match9 === void 0 ? void 0 : (_vm$match9$opponent = _vm$match9.opponent2) === null || _vm$match9$opponent === void 0 ? void 0 : _vm$match9$opponent.name) + " ")]), _c('b-row', {
    staticClass: "d-flex justify-space-around"
  }, [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, _vm._l((_vm$match10 = _vm.match) === null || _vm$match10 === void 0 ? void 0 : (_vm$match10$opponent = _vm$match10.opponent2) === null || _vm$match10$opponent === void 0 ? void 0 : _vm$match10$opponent.lineup, function (participant, index) {
    var _participant$particip2;
    return _c('b-badge', {
      key: index,
      staticClass: "m-1",
      attrs: {
        "variant": "primary"
      }
    }, [_vm._v(" " + _vm._s((participant === null || participant === void 0 ? void 0 : (_participant$particip2 = participant.participant) === null || _participant$particip2 === void 0 ? void 0 : _participant$particip2.name) || (participant === null || participant === void 0 ? void 0 : participant.name)) + " ")]);
  }), 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "d-flex justify-content-center mt-2"
  }, [_c('h4', [_vm._v(_vm._s(_vm.matchStatusInfo))])])], 1), (_vm$match11 = _vm.match) !== null && _vm$match11 !== void 0 && _vm$match11.opponent1_result && (_vm$match12 = _vm.match) !== null && _vm$match12 !== void 0 && _vm$match12.opponent2_result ? _c('b-row', [_c('b-col', {
    staticClass: "d-flex flex-column align-items-center mt-1",
    attrs: {
      "cols": "5"
    }
  }, [(_vm$match13 = _vm.match) !== null && _vm$match13 !== void 0 && _vm$match13.opponent1_score ? _c('div', [_c('h2', [_vm._v(_vm._s((_vm$match14 = _vm.match) === null || _vm$match14 === void 0 ? void 0 : _vm$match14.opponent1_score))])]) : _vm._e(), _c('div', [_c('b-badge', {
    staticClass: "p-sm-1 font-medium-2",
    attrs: {
      "variant": _vm.matchResultBadgeColor((_vm$match15 = _vm.match) === null || _vm$match15 === void 0 ? void 0 : _vm$match15.opponent1_result)
    }
  }, [_vm._v(" " + _vm._s(_vm.matchResultText((_vm$match16 = _vm.match) === null || _vm$match16 === void 0 ? void 0 : _vm$match16.opponent1_result)) + " ")])], 1)]), _c('b-col', {
    attrs: {
      "cols": "2"
    }
  }), _c('b-col', {
    staticClass: "d-flex flex-column align-items-center mt-1",
    attrs: {
      "cols": "5"
    }
  }, [(_vm$match17 = _vm.match) !== null && _vm$match17 !== void 0 && _vm$match17.opponent2_score ? _c('div', [_c('h2', [_vm._v(_vm._s((_vm$match18 = _vm.match) === null || _vm$match18 === void 0 ? void 0 : _vm$match18.opponent2_score))])]) : _vm._e(), _c('div', [_c('b-badge', {
    staticClass: "p-sm-1 font-medium-2",
    attrs: {
      "variant": _vm.matchResultBadgeColor((_vm$match19 = _vm.match) === null || _vm$match19 === void 0 ? void 0 : _vm$match19.opponent2_result)
    }
  }, [_vm._v(" " + _vm._s(_vm.matchResultText((_vm$match20 = _vm.match) === null || _vm$match20 === void 0 ? void 0 : _vm$match20.opponent2_result)) + " ")])], 1)])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
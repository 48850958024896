const MatchStatusType = {
  LOCKED: 1,
  WAITING: 2,
  READY: 3,
  RUNNING: 4,
  COMPLETE: 5,
  DISPUTED: 6,
}

export default MatchStatusType
